import Image from 'next/image';

import { Container } from '@/components/ui/flexbox';
import { TitleWrapper, List, Icon, Text } from './styles';

import { Heading } from '@swordhealth/ui-corporate';

const EverythingYouNeed = ({ content }) => {
  const { title, iconDesc, id } = content;

  return (
    <Container>
      <TitleWrapper>
        <Heading size="2xl" as="h2" id={`everything-you-need-title-${id}`}>
          {title}
        </Heading>
      </TitleWrapper>
      <List $colspan={iconDesc.length}>
        {iconDesc.map((item, index) => (
          <li key={item.id}>
            <Icon>
              <Image
                id={`everything-you-need-icon-${id}-${index}`}
                src={item?.icon?.url ?? item?.icon?.data?.attributes?.url}
                alt={
                  item?.icon?.alternativeText ?? item?.icon?.data?.attributes?.alternativeText ?? ''
                }
                width={80}
                height={80}
              />
            </Icon>
            <Heading as="h6" id={`everything-you-need-item-title-${id}-${index}`} size="md">
              {item.title}
            </Heading>
            <Text id={`everything-you-need-description-${id}-${index}`}>{item.description}</Text>
          </li>
        ))}
      </List>
    </Container>
  );
};

EverythingYouNeed.defaultConfig = {
  rounded: false,
  colored: false,
  spaceBottom: false,
  spaceTop: false,
  marginBottom: true,
};

export default EverythingYouNeed;
