import styled from 'styled-components';
import Section from '@/components/ui/Section';

export const StyledSection = styled(Section)`
  padding-bottom: 0;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: var(--ui-spacings-xl);

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    padding-top: var(--ui-spacings-lg);
  }
`;
