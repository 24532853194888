import Slider from '../Slider';

const GettingStarted = ({ content }) => {
  if (!content) return null;

  return <Slider content={content} withNumber name="getting-started" />;
};

GettingStarted.defaultConfig = {
  rounded: false,
  colored: false,
  spaceBottom: false,
  spaceTop: false,
  marginBottom: true,
};

export default GettingStarted;
