import NextImage from 'next/image';

import { Paragraph, RichText } from '@swordhealth/ui-corporate';
import { getImgData } from '@/utils/images';
import { Card, Grid, InnerWrapper, TitlesWrapper } from './styles';
import useBetterMediaQuery from '@/utils/useBetterMediaQuery';
import theme from '@/utils/styles-variables';

const UnevenGrid = ({ cards, sectionId, colored }) => {
  const isMobile = useBetterMediaQuery(`(max-width: ${theme.breakpoints.max_sm}px)`);
  const isTablet = useBetterMediaQuery(
    `(min-width: ${theme.breakpoints.min_md}px) and (max-width: ${theme.breakpoints.max_lg}px)`,
  );

  const populateImageCard = (cardIndex, image, mobileImage) => {
    if (!mobileImage && !image) {
      return null;
    }

    if (!isTablet && cardIndex === 0 && mobileImage) {
      return mobileImage;
    }

    if (!isMobile && cardIndex === 1 && image) {
      return image;
    }

    return isMobile && mobileImage ? mobileImage : image;
  };

  return (
    <Grid id={`uneven-grid-${sectionId}`}>
      {cards.map(({ id, title, description, image, mobileImage }, index) => {
        const imgData = image?.url ? image : getImgData(image?.data);
        const mobileImgData = mobileImage?.url ? mobileImage : getImgData(mobileImage?.data);
        const imageSrc = populateImageCard(index, imgData?.url, mobileImgData?.url);

        return (
          <Card key={id} $index={index} id={`card-uneven-grid-${index}`} $colored={colored}>
            <TitlesWrapper $index={index}>
              <InnerWrapper>
                <RichText as="h3" gradientBoldText id={`card-title-${sectionId}-${index}`}>
                  {title}
                </RichText>
                <RichText as={Paragraph} id={`card-description-${sectionId}-${index}`}>
                  {description}
                </RichText>
              </InnerWrapper>
            </TitlesWrapper>
            {imageSrc && index < 2 && (
              <NextImage
                id={`card-image-${sectionId}-${index}`}
                src={imageSrc}
                alt={imgData?.alternativeText ?? ''}
                fill
              />
            )}
          </Card>
        );
      })}
    </Grid>
  );
};

export default UnevenGrid;
