import { Container, SectionHeader } from '@swordhealth/ui-corporate';
import useBetterMediaQuery from '@/utils/useBetterMediaQuery';
import theme from '@/utils/styles-variables';
import { ButtonWrapper } from './styles';
import UnevenGrid from './UnevenGrid/UnevenGrid';
import CampaignButton from './CampaignButton';
import EnrollButton from '@/app/components/core/EnrollButton';
import { products } from '@/utils/products';
import { useContext } from 'react';
import ClientContext from '@/contexts/client.context';

const UnevenGridSection = ({ content, campaign, scrollTop, isOrganicLP }) => {
  const {
    id,
    titles: { upperLabel, title, description, titleSize, xAlign = 'left' },
    cards,
    buttonLabel,
    config,
  } = content;
  const isMobile = useBetterMediaQuery(`(max-width: ${theme.breakpoints.max_sm}px)`);
  const { client, openModal } = useContext(ClientContext);
  const headerAlign = isMobile ? 'center' : !xAlign ? 'left' : xAlign;

  return (
    <div>
      <Container xAlign={headerAlign}>
        <SectionHeader
          title={title}
          label={upperLabel}
          description={description ?? ''}
          xAlign={headerAlign}
          titleSize={titleSize ?? '2xl'}
        />
        <UnevenGrid sectionId={id} cards={cards} colored={!config?.colored} />
      </Container>
      <ButtonWrapper>
        {(campaign || client || scrollTop) && (
          <CampaignButton
            label={buttonLabel ?? 'Get Started'}
            scrollTop={scrollTop}
            isOrganicLP={isOrganicLP}
          />
        )}
        {!campaign && !client && !scrollTop && (
          <EnrollButton
            id={`uneven-grid-button-${id}`}
            isActive={client.isActive}
            buttonLocation="Uneven Grid"
            openModal={() =>
              openModal({
                buttonText: buttonLabel ?? 'Get Started',
                buttonLocation: 'uneven grid section',
              })
            }
            client={client}
            buttonLabel={buttonLabel}
            scrollTop={scrollTop}
            product={products[client?.product?.key?.toUpperCase()]}
            redirectToApp={client?.redirectToApp}
            triage={client?.hasTriage}
            isOrganicLP={isOrganicLP}
          />
        )}
      </ButtonWrapper>
    </div>
  );
};

UnevenGridSection.defaultConfig = {
  rounded: true,
  colored: false,
  spaceBottom: false,
  spaceTop: false,
  marginBottom: false,
};

export default UnevenGridSection;
