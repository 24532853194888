import styled, { css } from 'styled-components';

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, minmax(auto, 320px));
  gap: 16px;
  padding-top: var(--ui-spacings-lg);

  @media (min-width: ${(props) => props.theme.breakpoints.min_md}px) and (max-width: ${(props) =>
      props.theme.breakpoints.max_lg}px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, auto);
    grid-template-areas:
      'card1 card1'
      'card2 card3'
      'card4 card4';
    padding-top: var(--ui-spacings-sm);

    #card-uneven-grid-0 {
      grid-area: card1;
      grid-column: 1 / -1;
    }
    #card-uneven-grid-2 {
      grid-area: card2;
    }
    #card-uneven-grid-3 {
      grid-area: card3;
    }
    #card-uneven-grid-1 {
      grid-area: card4;
      grid-column: 1 / -1;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-template-areas:
      'card1'
      'card2'
      'card3'
      'card4';
    padding-top: 8px;

    #card-uneven-grid-0 {
      grid-area: card1;
    }
    #card-uneven-grid-2 {
      grid-area: card2;
    }
    #card-uneven-grid-3 {
      grid-area: card3;
    }
    #card-uneven-grid-1 {
      grid-area: card4;
    }
  }
`;

export const Card = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  width: 100%;
  border-radius: 32px;
  background: ${(props) => props.theme.colors.neutral[200]};
  overflow: hidden;

  ${(props) =>
    !props.$colored &&
    css`
      background: ${(props) => props.theme.colors.white};
    `}
  img {
    object-fit: contain;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.min_sm}px) {
    img {
      object-position: bottom;
      margin-top: 0;
    }
  }

  ${(props) =>
    props.$index === 0 &&
    css`
      display: flex;
      flex-direction: column;
      grid-column: 1 / 2;
      grid-row: 1 / 3;

      img {
        position: unset !important;
        display: flex;
        justify-content: flex-end;
        object-position: bottom;
      }

      @media (max-width: ${(props) => props.theme.breakpoints.max_lg}px) {
        flex-direction: row;
        img {
          position: unset !important;
          object-position: right;
        }
      }

      @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
        display: flex;
        flex-direction: column;

        img {
          display: flex;
          object-fit: contain !important;
          object-position: unset !important;
          position: unset !important;
        }
      }
    `}

  ${(props) =>
    props.$index === 1 &&
    css`
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      grid-column: 2 / 4;
      grid-row: 1 / 2;

      img {
        position: unset !important;
        object-position: right;
      }

      @media (max-width: ${(props) => props.theme.breakpoints.max_lg}px) {
        img {
          position: unset !important;
          object-position: right;
          width: auto !important;
        }
      }

      @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
        flex-direction: column;

        img {
          position: unset !important;
          display: flex;
          object-position: unset !important;
        }
      }
    `}

    ${(props) =>
    props.$index === 2 &&
    css`
      grid-column: 2 / 3;
      grid-row: 2 / 3;
    `}

    ${(props) =>
    props.$index === 3 &&
    css`
      grid-column: 3 / 4;
      grid-row: 2 / 3;
    `}
`;

export const TitlesWrapper = styled.div`
  display: flex;
  padding: var(--ui-spacings-2xl);
  flex-direction: column;
  gap: var(--ui-spacings-sm);

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    padding: var(--ui-spacings-lg);
  }

  ${(props) =>
    props.$index === 0 &&
    css`
      padding: 48px 48px 0 48px;

      @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
        padding: 32px 32px 8px 32px;
      }
    `}

  ${(props) =>
    props.$index === 1 &&
    css`
      padding: 48px 0 32px 48px;

      @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
        padding: 32px 32px 8px 32px;
      }
    `}
`;

export const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  gap: 16px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    width: 100%;
    align-items: center;
  }

  h3 {
    font-size: 40px;
    line-height: 48px;
    letter-spacing: -1px;

    @media (max-width: ${(props) => props.theme.breakpoints.max_lg}px) {
      font-size: 28px;
      line-height: 32px;
      letter-spacing: -0.5px;
    }

    @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
      text-align: center;
      font-size: 22px;
      line-height: 26px;
    }
  }

  p {
    @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
      font-size: 14px;
      line-height: 24px;
      text-align: center;
    }
  }
`;
