import { useContext, useMemo, Fragment } from 'react';
import ClientContext from '@/contexts/client.context';
import { LogosLine, Logo, SplitBar } from './styles';
import getImgSrcWorkaround from '@/utils/images';
import Image from 'next/image';
import cn from 'classnames';

export const Logos = ({
  logos,
  centered = false,
  showSwordLogo = false,
  headerContent = false,
  withBackground = false,
  noPadding = false,
  customHeight = 64,
}) => {
  const finalLogos = useMemo(() => {
    return logos?.slice(0, showSwordLogo ? 2 : 3) || [];
  }, [showSwordLogo, logos]);

  const { client } = useContext(ClientContext);
  const productKey = client?.product?.key;
  const productLogo = client?.product?.logo;

  if (!finalLogos?.length && !showSwordLogo) {
    return null;
  }

  const isPlatform = productKey === 'platform';
  const logoCount = finalLogos ? finalLogos.length + (showSwordLogo ? 1 : 0) : 1;

  const renderSwordLogo = (index) => (
    <Logo
      key={productLogo ? 'sub-brand-logo' : 'sword-logo'}
      className={cn({
        'sword-logo': !productLogo || isPlatform,
        'sub-brand-logo': productLogo && !isPlatform,
        withBackground,
      })}
      $logoCount={logoCount}
    >
      <Image
        id={index !== undefined ? `header-logo-${index}` : 'header-sword-logo'}
        src={productLogo ?? '/sword__colored.svg'}
        alt={productKey ?? 'Sword Health'}
        loading="eager"
        width={200}
        height={productLogo ? 48 : 28}
      />
    </Logo>
  );

  return (
    <LogosLine
      className={cn({ centered, headerContent, platform: isPlatform, withBackground, noPadding })}
      $logoCount={logoCount}
    >
      {finalLogos?.map((logo, index) => {
        const logoName = logo?.name ?? logo?.attributes?.name;
        const logoUrl = logo?.url ?? logo?.attributes?.url;
        const altText = logo?.alternativeText ?? logo?.attributes?.alternativeText;

        return (
          <Fragment key={logoUrl || logoName || index}>
            {logoName === 'sword__colored.svg' ? (
              renderSwordLogo(index)
            ) : (
              <Logo
                className={cn('client-logo', { withBackground })}
                $logoCount={logoCount}
                $customHeight={customHeight}
                withBackground={withBackground}
              >
                <Image
                  id={`header-logo-${index}`}
                  alt={altText}
                  src={getImgSrcWorkaround(logoUrl)}
                  loading="eager"
                  width={224}
                  height={customHeight}
                />
              </Logo>
            )}
            {index < finalLogos.length - 1 && <SplitBar />}
          </Fragment>
        );
      })}

      {showSwordLogo && (
        <Fragment>
          {finalLogos.length > 0 && <SplitBar />}
          {renderSwordLogo()}
        </Fragment>
      )}
    </LogosLine>
  );
};
