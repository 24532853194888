import Slider from '../Slider';

const ExpertCare = ({ content }) => {
  if (!content) return null;

  return <Slider content={content} name="expert-care" reverse />;
};

ExpertCare.defaultConfig = {
  rounded: false,
  colored: false,
  spaceBottom: false,
  spaceTop: false,
  marginBottom: true,
};

export default ExpertCare;
